.loginPage {
    padding: 70px 0;
    background: url(../../components//img/loginBanner.png);
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;align-items: center;
    
}
.loginPage .commityImg {
    max-width: 420px;
    margin: auto;
}
.ai-center {
    align-items: center;
}
.loginPage .loginForm {
    background: #081127;
    border-radius: 25px;
    padding: 60px 30px;
}
.loginPage .loginForm h3{
    /* font-family: 'Gilroy'; */
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-transform: capitalize;
}
.loginPage .loginForm .desc{
    /* font-family: 'Gilroy'; */
    color: #8BA4BF;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    /* line-height: 30px; */
    margin-bottom: 20px;
}
.loginPage .loginForm label{
    /* font-family: 'Gilroy'; */
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #8BA4BF;
    margin-bottom: 5px;
}
.loginPage .loginForm input{
    background: #162136;
    border-radius: 12px;
    border: none;
    color: white;
    margin: 5px 0;
    margin-bottom: 20px;
}
.loginPage .loginForm .forget{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    text-transform: capitalize;
    background: linear-gradient(91.24deg, #4200FF 0.26%, #FF02C7 99.58%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    cursor: pointer;
}
.loginPage .loginForm .forget:hover{
    background: linear-gradient(91.24deg, #4200FF 100%, #FF02C7 99.58%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.loginPage .loginForm .loginBtn{
    background: linear-gradient(91.24deg, #4200FF 0.26%, #FF02C7 99.58%);
    border-radius: 12px;
    text-align: center;
    color: white;
    text-transform: uppercase;
    padding: 15px;
    margin-top: 100px;
    cursor: pointer;
    transition: 0.3s;
    border: none;
    width: 100%;
}
.dib {
    display: inline-block;
}
.display-block {
    display: block;
}
.loginPage .loginForm .loginBtn:hover{
    background: linear-gradient(91.24deg, #4200FF 100.26%, #FF02C7 99.58%);
    transition: 0.3s;
}
.loginPage .loginForm .noAccount{
    font-family: 'BR Firma';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    margin: 16px 0 4px 0;
}
.loginPage .loginForm .signup:hover{
    color: #4200FF;
}
.loginPage .loginForm .signup{
    color: #F302CA;
    text-align: center;
    cursor: pointer;
    width: max-content;
    margin: auto;
}