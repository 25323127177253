.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.dark-font {
  color: #333333;
}

.status-loaded {
  color: #17c903 !important;
}

.overflow-hidden {
  overflow: hidden;
}

.table-hover > tbody > tr:hover > * {
  background: #202d4a !important;
  box-shadow: none !important;
  color: var(--dashThemeColor) !important;
}

.w-mc {
  width: max-content;
}

.text-right {
  text-align: right;
}

.w-200 {
  width: 200%;
}

.w-190 {
  width: 190%;
}

.themeTable .btn:focus {
  box-shadow: 0 0 0 0.2rem rgb(94 10 111 / 25%);
}

.themeTable table {
  border-collapse: separate;
  border-spacing: 0;
}

.themeTable table th:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-left: 1px solid #081127;
}

.themeTable table th:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-right: 1px solid #081127;
}

.themeTable table th {
  color: var(--dashThemeColor);
  font-weight: 500;
  font-size: 16px;
  border-top: 1px solid #081127;
  border-bottom: 1px solid #081127;
  white-space: nowrap;
  text-align: center;
  background-color: #081127;
}

.themeTable table td {
  /* border-color: transparent !important; */
  white-space: nowrap;
  vertical-align: middle;
  border-bottom: 1px solid rgba(139, 164, 191, 0.23) !important;
  border-top: none;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
}

.themeTable table td {
  color: var(--dashThemeColor);
  font-weight: normal;
  font-size: 16px;
  width: max-content;
}
.themeTable .tableHeading {
  /* font-family: 'Gilroy'; */
  font-weight: 700;
  font-size: 20px;
}
.themeTable .profileDiv {
  display: flex;
  align-items: center;
}
.themeTable .profileDiv .imgDiv {
  width: 45px;
  height: 45px;
  overflow: hidden;
  margin-right: 20px;
}
.themeTable .profileDiv .imgDiv img {
  max-width: 100%;
}
.themeTable .profileDiv .name {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}
.themeTable .price {
  color: #27ae60;
  font-size: 18px;
  font-weight: 600;
}
.themeTable .refund {
  color: var(--secondary);
  font-size: 18px;
  font-weight: 600;
}

/* .themeTable table td div:last-child{
    color: #95A6AD;
    font-weight: 400;
    font-size: 12px;
    width: max-content;

} */
.font-500 {
  font-weight: 500;
}

.font-bold {
  font-weight: bold;
}

.w-12 {
  width: 12px;
}

.w-75 {
  width: 75%;
}

.fs-19 {
  font-size: 19px;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  position: relative;
  border: 1px solid black;
  margin-bottom: 4px;
  margin-top: 4px;
}

.circle-light {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  position: relative;
  border: 1px solid #8f9fb4;
  margin-bottom: 4px;
  margin-top: 4px;
}

.circle-fill {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  position: relative;
  background-color: #333333;
  border: 1px solid #333333;
  margin-bottom: 4px;
  margin-top: 4px;
}

.vertical-line-light {
  width: 2px;
  height: 40px;
  background: #8f9fb4;
}

.vertical-line-dark {
  width: 2px;
  height: 50px;
  background: #333333;
}

.font-weight-600 {
  font-weight: 600;
}

.bgRed {
  background: #ff007a;
}

.bgGreen {
  background: #04a55e;
}

.bgYellow {
  background: #ffb903;
}

.jc-sb {
  justify-content: space-between;
}
.dashboardMain {
  padding-bottom: 20px;
}
.dashboardMain .row1 {
  margin-bottom: 20px;
}
.dashboardMain .themeCard {
  background: #142240;
  border-radius: 16px;
  overflow: hidden;
}
.dashboardMain .card-header {
  background-color: #142240 !important;
}
.dashboardMain .itemCard {
  display: flex;
  align-items: center;
  padding: 25px;
  margin-bottom: 15px;
}

.dashboardMain .itemCard .itemImg {
  width: 75px;
  height: 75px;
  margin-right: 15px;
}
.dashboardMain .itemCard h3 {
  /* font-family: 'Gilroy'; */
  font-weight: 700;
  font-size: 26px;
  line-height: 39px;
  color: #fff;
}
.dashboardMain .itemCard p {
  /* font-family: 'Gilroy'; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: var(--dashThemeColor);
  margin-bottom: 0;
}
.dashboardMain .row1 .col2 {
  padding: 20px 25px;
  margin-bottom: 15px;
  height: 100%;
}
.dashboardMain .row1 .col2 h3 {
  font-size: 22px;
  margin-bottom: 10px;
}
.dashboardMain .row1 .chartRow {
  display: flex;
  align-items: center;
}
.dashboardMain .row1 .chartRow .content {
  /* margin-left: 15px; */
  flex: 1 0;
}
.dashboardMain .row1 .chartRow .content .colorRow {
  display: flex;
  margin-bottom: 4px;
  font-size: 15px;
}
.dashboardMain .row1 .chartRow .content .circle.blue {
  background: #025ef3;
}
.dashboardMain .row1 .chartRow .content .circle.pink {
  background: #f302ca;
}
.dashboardMain .row1 .chartRow .content .circle.purple {
  background: #7600ef;
}
.dashboardMain .row1 .chartRow .content .circle.yellow {
  background: #f2c94c;
}
.dashboardMain .row1 .chartRow .content .circle.red {
  background: #ea0000;
}
.dashboardMain .row1 .chartRow .content .circle.orange {
  background: #ffa500;
}
.dashboardMain .row1 .chartRow .content .circle.green {
  background: #00af00;
}
.dashboardMain .row1 .chartRow .content .circle {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.dashboardMain .row1 .total {
  background: #202d4a;
  border-radius: 12px;
  padding: 10px 15px;
  width: 100%;
  margin-top: 25px;
  font-size: 15px;
}
.dashboardMain .amountMainCard {
  padding: 16px;
  height: 100%;
}
.dashboardMain .amountMainCard h4 {
  /* font-family: 'Gilroy'; */
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
}
.dashboardMain .amountMainCard .card1 {
  background: linear-gradient(194.93deg, #577cff 10.86%, #0038ff 89.81%);
}
.dashboardMain .amountMainCard .card2 {
  background: linear-gradient(182.69deg, #ffa461 2.15%, #ff6e04 97.72%);
}
.dashboardMain .amountMainCard .card3 {
  background: linear-gradient(
    180deg,
    #ff6a56 0%,
    #ff6a56 0%,
    #ff6a56 0%,
    #ff6a56 0.01%,
    #ff0404 98.61%
  );
}
.dashboardMain .amountMainCard .priceMain {
  border-radius: 16px;
  padding: 30px 15px;
  text-align: center;
  margin-bottom: 16px;
  cursor: pointer;
}
.dashboardMain .amountMainCard .priceMain .price {
  /* font-family: 'BR Firma'; */
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #fff;
}
.dashboardMain .amountMainCard .priceMain .liquidate {
  /* font-family: 'BR Firma'; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
}
.dashboardMain .amountMainCard .priceMain .max {
  /* font-family: 'BR Firma'; */
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  opacity: 0.7;
}
.themeBtn:hover {
  background: linear-gradient(91.24deg, #4200ff 100.26%, #ff02c7 99.58%);
  transition: 0.3s;
}
.themeBtn {
  background: linear-gradient(91.24deg, #4200ff 0.26%, #ff02c7 99.58%);
  border-radius: 12px;
  color: white;
  text-transform: uppercase;
  padding: 13px;
  text-align: center;
  margin-top: 100px;
  cursor: pointer;
  border: none;
}
.dn-lg {
  display: none;
}
.dn-sm {
  display: block;
}

.dashboardMain .filterRow {
  display: flex;
  align-items: center;
  padding: 6px 16px;
  overflow-x: auto;
}
.dashboardMain .filterRow .filBtn img {
  margin-right: 6px;
}
.dashboardMain .filterRow .filBtn svg {
  margin-right: 6px;
  width: 18px;
}
.dashboardMain .filterRow .filBtn.active svg,
.dashboardMain .filterRow .filBtn.active path {
  fill: #fff;
}
.dashboardMain .filterRow .filBtn.active {
  background: linear-gradient(91.24deg, #4200ff 0.26%, #ff02c7 99.58%);
  color: #fff;
}
.dashboardMain .filterRow .filBtn {
  background: #202d4a;
  /* padding: 8px 16px; */
  padding: 6px 13px;
  margin-right: 8px;
  border-radius: 27px;
  display: flex;
  /* font-family: 'Gilroy'; */
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: var(--dashThemeColor);
  cursor: pointer;
}
.modal {
  backdrop-filter: blur(10px);
}
.modal-content {
  --bs-modal-bg: #081127;
}
.modal-title {
  color: #fff;
}

.liquidityEditForm label {
  margin-bottom: 7px;
  color: #8ba4bf;
  font-weight: 600;
}
.liquidityEditForm input:focus {
  background: #142240;
  color: #fff;
}
.liquidityEditForm input {
  background: #142240;
  color: #8ba4bf;
  border: none;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .dashboardMain .amountMainCard h4 {
    font-size: 17px;
  }
  .dashboardMain .amountMainCard .priceMain .price {
    font-size: 25px;
  }
  .dashboardMain .amountMainCard .priceMain .liquidate {
    font-size: 16px;
  }
  .dashboardMain .amountMainCard .priceMain .max {
    font-size: 13px;
  }
  .dashboardMain .row1 .total {
    font-size: 14px;
  }
  .dashboardMain .itemCard .itemImg {
    width: 70px;
    height: 70px;
  }
  .dashboardMain .itemCard h3 {
    font-size: 24px;
    margin: 0;
  }
  .dashboardMain .itemCard p {
    font-size: 17px;
  }
  .topbar .pickerDiv {
    max-width: 280px;
  }
}

@media screen and (max-width: 1200px) {
  .dashboardMain .themeCard {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 992px) {
  .dashboardMain .itemCard {
    height: 47%;
  }
}
@media screen and (max-width: 992px) {
  .dashboardMain .row1 .col2 {
    margin-top: 20px;
    margin-bottom: 0px;
    height: auto;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .dashboardMain .row1 .col2 {
    padding: 15px;
  }
  .dashboardMain .itemCard {
    padding: 15px;
  }
}
@media screen and (max-width: 576px) {
  .dashboardMain .row1 .col2 {
    padding: 15px;
  }
  .dashboardMain .itemCard {
    padding: 15px;
  }

  .themeTable table td {
    font-size: 14px;
  }
  .dn-lg {
    display: block;
  }
  .dn-sm {
    display: none;
  }
  .dashboardMain .row1 .col2 {
    padding: 15px;
  }
  .profile-name {
    font-size: 17px;
  }
  .status {
    font-size: 13px;
  }
  .dashboardMain .itemCard .itemImg {
    height: 60px;
    width: 60px;
  }
  .dashboardMain .itemCard h3 {
    font-size: 24px;
    margin-bottom: 0;
  }
  .dashboardMain .itemCard p {
    font-size: 15px;
  }
  .dashboardMain .row1 .chartRow .content .colorRow {
    font-size: 14px;
  }
  .dashboardMain .amountMainCard .priceMain .price {
    font-size: 28px;
  }
  .dashboardMain .amountMainCard .priceMain .liquidate {
    font-size: 16px;
  }
}
.orderDetails {
  width: 100%; /* Ensures the orderDetails div takes the full width available */
}

.orderDetails .mb-3 {
  display: flex;
  justify-content: space-between; /* Aligns label and value on the same row */
  align-items: center; /* Vertically centers the items */
}

.orderDetails label {
  font-weight: bold; /* Makes the label bold */
  margin-right: 10px; /* Adds space between label and value */
}

.form-control-plaintext {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  flex: 1; /* Allows the value to take the remaining space */
  text-align: right; /* Aligns the text to the right */
}

.form-control-plaintext-left {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.form-control-plaintext-green {
  color: var(--green);
  font-size: 18px;
  font-weight: 600;
  flex: 1; /* Allows the value to take the remaining space */
  text-align: right; /* Aligns the text to the right */
}

.form-control-plaintext-pink {
  color: var(--secondary);
  font-size: 18px;
  font-weight: 600;
  flex: 1; /* Allows the value to take the remaining space */
  text-align: right; /* Aligns the text to the right */
}
.title {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 8px;
}
.subtitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}
.text {
  margin-bottom: 20px;
}
.datepicker-popper {
  z-index: 1000;
}

.datepicker-calendar {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #001f3f; /* Dark blue background */
}

.pickerDiv .form-control {
  background-color: var(--blue); /* Darker blue background for input field */
  color: #ffffff; /* White text color for better contrast */
  border: 1px solid var(--dark-grey); /* Border color */
  border-radius: 4px; /* Rounded corners */
  padding: 10px; /* Padding inside the input */
  transition: border-color 0.2s; /* Transition for border color */
  font-weight: 600;
}

.pickerDiv .form-control:focus {
  border-color: var(--secondary); /* Border color on focus */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Shadow on focus */
}

.pickerDiv .form-control {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 10px;
  transition: border-color 0.2s;
}

.pickerDiv .form-control:focus {
  border-color: var(--secondary); /* Border color on focus */
  box-shadow: 0 0 0 0.2rem rgba(225, 0, 255, 0.25);
}

.alert {
  color: var(--secondary);
}

.white-text {
  color: white;
}
