.rounded-full {
  border-radius: 9999px;
}
.min-h-40 {
  min-height: 40px;
}
.min-w-131px {
  min-width: 131px;
}
@media screen and (min-width: 576px) {
  .p-sm-20px {
    padding: 20px;
  }
}
.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 16px;
}
.productCard {
  border-radius: 17px;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background: #202d4a;
}

.productCard {
  position: relative;
  text-align: center;
  padding: 20px;
}
.productCard .productImg {
  max-height: 80px;
  max-width: 80px;
  margin: 0 auto 14px;
  position: relative;
}
.productCard .edit {
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 1;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
}
.productCard .delete {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
}
.productCard .productImg img {
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
}
.productCard .productDetails h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}
.productCard .productDetails .price {
  color: #27ae60;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.productCard .productDetails .outOfStock {
  color: #ff2f04;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.filBtn.icon svg {
  margin: 0 !important;
}
.mb-20 {
  margin-bottom: 20px;
}
.import-export-btn {
  font-weight: 700;
  background-color: #202d4a;
}
.right-side-bar-content {
  .item {
    font-size: 16px;
    font-weight: 500;
    color: #8ba4bf;
    margin-top: 28px;
    .subtitle {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    .text {
      margin-bottom: 20px;
    }
    .form-label {
      color: #8ba4bf;
    }
    .__form-control {
      background-color: #202d4a;
      color: #fff;
      option {
        background: #000;
        color: #ffffff;
      }
    }
    .form-select {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    }
    .pos-submit-btn {
      font-weight: 700;
      @media screen and (min-width: 992px) {
        min-height: 60px;
      }
    }
  }
}

.themeBtn {
  &.btn-success {
    background: #27ae60;
    border-color: #27ae60;
  }
  &.btn-outline-danger {
    background: transparent;
    border: 2px solid #f15c61;
    color: #f15c61;
    &:hover {
      background-color: #f15c61;
      color: #fff;
    }
  }
  &.btn-dark {
    background: #081127;
  }
}
.gap-12px {
  gap: 12px;
}
.right-sidebar-history-item {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 15px;
  background: #202d4a;
  .cont {
    width: 0;
    flex-grow: 1;
    h6 {
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    .price {
      font-size: 20px;
      font-weight: 600;
      font-family: "BR Firma";
    }
    .text-danger {
      color: #ff2f04;
    }
    .text-success {
      color: #34c759;
    }
  }
}
.mb-28 {
  margin-bottom: 28px;
}
.filter-buttons {
  .themeBtn:not(.active) {
    background: #081127;
    color: #fff;
  }
}
.add-product-title {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(#fff, 0.1);
  display: flex;
  align-items: center;
  line-height: 29px;
}
.w-0 {
  width: 0;
}
.form-switch {
  .form-check-input {
    box-shadow: none !important;
    height: 24px;
  }
  .form-check-input:checked {
    border-color: #f302ca;
    background-color: #f302ca;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23142240%27/%3e%3c/svg%3e");
  }
}
.border-y {
  border-bottom: 1px solid rgba(#fff, 0.1);
  border-top: 1px solid rgba(#fff, 0.1);
}
.max-w-200 {
  max-width: 200px;
}
.productCard .color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 15px;
  background: #27ae60;
  border: 3px solid #081127;
}
.pos-modal {
  .modal-header {
  }
  .modal-dialog {
    --bs-modal-width: 420px;
  }
  .modal-content {
    --bs-modal-bg: #142240;
  }
  .modal-body {
    padding-top: 0;
  }
}
.icons-grid {
  // 2icons view
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  padding-right: 15px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  .productCard {
    padding-block: 30px;
    border: 2px solid transparent;
    cursor: pointer;
    &.active {
      border-color: #f302ca;
    }
  }
  // scrollbar
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #f302ca;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #202d4a;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.colors-wrapper {
  border-radius: 50px;
  border: 4px solid #202d4a;
  background: #202d4a;
  margin-bottom: 20px;
  .color {
    aspect-ratio: 1;
    border-radius: 50%;
    width: 48px;
  }
  .swiper-slide {
    width: 48px;
  }
}
.add-pos-btn {
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  box-shadow: none;
  color: #f302ca;
  font-weight: 800;
  display: flex;
  align-items: center;
  gap: 12px;
  *[fill="white"] {
    fill: #f302ca;
  }
}
.sidebar-dark .nav-item .nav-link.active {
  color: #f302ca;
}
.horizontal {
  display: flex; // Ensures the container is a flex container
  flex-direction: row; // Aligns children (prices) in a row
  justify-content: center; // Centers the items horizontally
  align-items: center; // Aligns items vertically in the center
}
.ticket-info {
  display: flex;
  align-items: baseline; /* Align items vertically in the center */
}

.color-circle {
  width: 12px; /* Size of the circle */
  height: 12px; /* Size of the circle */
  border-radius: 50%; /* Makes the div a circle */
  margin-left: 2px; /* Space between the circle and the ticket number */
  margin-right: 5px; /* Space between the circle and the ticket number */
}

.ticket-number {
  color: white;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
