@mixin breakpoint($point) {
  @if $point==sm {
    @media (min-width: 576px) {
      @content;
    }
  } @else if $point==max-sm {
    @media (max-width: 575px) {
      @content;
    }
  } @else if $point==md {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point==max-md {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $point==lg {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $point==max-lg {
    @media (max-width: 991px) {
      @content;
    }
  } @else if $point==xl {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $point==max-xl {
    @media (max-width: 1199px) {
      @content;
    }
  } @else if $point==xxl {
    @media (min-width: 1400px) {
      @content;
    }
  } @else if $point==max-xxl {
    @media (max-width: 1399px) {
      @content;
    }
  }
}
@import url("https://fonts.cdnfonts.com/css/gilroy-bold");

@font-face {
  font-family: "BR Frima";
  src: url(./fonts/BRFirmaMedium.otf) format(woff);
  font-style: normal;
}
@font-face {
  font-family: "BR Frima Light";
  src: url(./fonts/BRFirmaLight.otf) format(woff);
  font-style: normal;
}
@font-face {
  font-family: "BR Frima Bold";
  src: url(./fonts/BRFirmaBold.otf) format(woff);
  font-style: normal;
}
@font-face {
  font-family: "BR Frima Semibold";
  src: url(./fonts/BRFirmaSemiBold.otf) format(woff);
  font-style: normal;
}

:root {
  --white: #fff;
  --section: #0f192f;
  --footer: #030a1b;
  --title: #ffffff;
  --body: #081127;
  --gradient: linear-gradient(91.24deg, #4200ff 0.26%, #ff02c7 99.58%);
  --primary: #4200ff;
  --secondary: #ff02c7;
  --pink: #de03b9;
  --body-clr: #8ba4bf;
  --gilroy-bold: "Gilroy-Bold", sans-serif;
  --social-text: #ff02c7;
  --bg-color: #1a2339;
  --green: #27ae60;
  --grey: #ccc;
  --light-grey: #eee;
  --light-grey1: #ddd;
  --light-grey2: #999;
  --dropdown-bg-color: #f2f9fc;
  --dark-grey: #333;
  --blue: #202d4a;
  --blue2: #142240;
  --violet: #f302ca;
}
body {
  background: var(--body);
  color: #8ba4bf;
  font-family: "BR Frima";
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "BR Frima Bold";
  color: var(--white);
}
@include breakpoint(xl) {
  .container {
    max-width: 1260px;
  }
}
@include breakpoint(xxl) {
  .container {
    max-width: 1420px;
  }
}
.header-btn {
  box-shadow: none;
  border: none;
  outline: none;
  padding: 13px 25px;
  text-decoration: none;
  text-transform: uppercase;
  &.btn-outline {
    position: relative;
    background: var(--gradient);
    border-radius: 10px;
    &::before {
      content: "";
      inset: 2px;
      border-radius: 8px;
      background: var(--body);
      position: absolute;
      transition: all ease 0.3s;
      @include breakpoint(max-md) {
        background: var(--white);
      }
    }
    span {
      position: relative;
      z-index: 1;
      background: linear-gradient(91.24deg, #4200ff 0.26%, #ff02c7 99.58%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent;
      background-clip: text;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      transition: all ease 0.3s;
    }
    &:hover {
      &::before {
        opacity: 0;
      }
      span {
        background: var(--white);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        background-clip: text;
      }
    }
  }
}
/* Header Menu */
header {
  padding: 25px 0;
  position: sticky;
  z-index: 9999;
  top: 0;
  width: 100%;
  transition: all ease 0.3s;
  background: var(--body);
  &.sticky {
    box-shadow: 0px 4px 4px #ff02c828;
  }
}

.menu {
  display: flex;
  flex-wrap: wrap;
  li {
    list-style: none;
    a {
      text-decoration: none;
      text-transform: capitalize;
      color: var(--title);
      font-size: 16px;
      line-height: 20px;
      transition: all ease 0.3s;
      font-weight: 500;
      display: block;
      &:hover {
        color: var(--secondary);
      }
      padding: 10px 15px;
    }
  }
}
ul {
  padding: 0;
  margin: 0;
}
.menu-area {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  .menu {
    margin-left: auto;
    margin-right: 15px;
  }
}

@include breakpoint(max-md) {
  header {
    padding: 18px 0;
  }
  .header-wrapper {
    .header-bar {
      position: absolute;
      left: 25px;
    }
    .logo {
      max-width: 220px;
      margin: 0 auto;
    }
    .menu-area {
      flex-wrap: wrap;
      position: absolute;
      top: 100%;
      padding: 30px 25px;
      background: var(--white);
      width: calc(100% - 50px);
      border-radius: 0 0 10px 10px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      left: 25px;
      transition: all ease 0.3s;
      &:not(.active) {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-10px);
      }
      .menu {
        width: 100%;
        flex-direction: column;
        margin-bottom: 25px;
        li {
          a {
            padding: 5px 0;
            color: #0c0c1c;
          }
        }
      }
    }
  }
}
.banner-section {
  &::before {
    content: "";
    inset: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    @include breakpoint(max-md) {
      background: rgba(0, 0, 0, 0.7);
    }
  }
  position: relative;
  min-height: 220px;
  background-size: contain;
  .banner-content {
    position: relative;
    z-index: 1;
    text-align: center;
    //max-width: 760px;
    margin: 0 auto;
    .title {
      font-size: 30px;
    }
    .long-title {
      font-size: 18px;
    }
  }
  padding: 100px 0;
  @include breakpoint(md) {
    padding: 120px 0;
    .banner-content {
      .title {
        font-size: 60px;
      }
      .long-title {
        font-size: 30px;
      }
    }
  }
  @include breakpoint(lg) {
    padding: 140px 0;
    min-height: 600px;
    .banner-content {
      .title {
        font-size: 70px;
      }
      .long-title {
        font-size: 35px;
      }
    }
  }
  @include breakpoint(xl) {
    padding: 140px 0;
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-content {
      .title {
        font-size: 90px;
      }
      .long-title {
        font-size: 45px;
      }
    }
  }
  @include breakpoint(xxl) {
    padding: 140px 0;
    min-height: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-content {
      .title {
        font-size: 100px;
      }
      .long-title {
        font-size: 55px;
      }
    }
  }
}
.clear-banner-section {
  position: relative;
  min-height: 220px;
  background-size: contain;
  .banner-content {
    position: relative;
    z-index: 1;
    text-align: center;
    //max-width: 760px;
    margin: 0 auto;
  }
  padding: 100px 0;
  @include breakpoint(md) {
    padding: 120px 0;
    min-height: 300px;
  }
  @include breakpoint(lg) {
    padding: 140px 0;
    min-height: 600px;
  }
  @include breakpoint(xl) {
    padding: 140px 0;
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @include breakpoint(xxl) {
    padding: 140px 0;
    min-height: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
footer {
  padding: 50px 0 70px;
  background: var(--footer);
  @include breakpoint(md) {
    padding: 70px 0;
  }
}
.footer-logo {
  max-width: 106px;
  margin: 0 auto 40px;
  display: block;
  @include breakpoint(md) {
    margin-bottom: 50px;
  }
}
.social-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 34px;
  li {
    list-style: none;
    a {
      svg {
        path {
          transition: all ease 0.3s;
        }
      }
      &:hover {
        svg path {
          fill: var(--secondary);
        }
      }
    }
  }
}
.footer-links {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  li {
    list-style: none;
    a {
      color: var(--body-clr);
      text-decoration: none;
      font-size: 14px;
      line-height: 18px;
      display: block;
    }
  }
  @include breakpoint(md) {
    flex-direction: row;
    li:not(:first-child) {
      list-style: disc;
      padding-left: 10px;
      margin-left: 15px;
    }
  }
}
.event-desc-section {
  padding: 50px 0;
  @include breakpoint(md) {
    padding: 120px 0;
  }
}
.section-header {
  text-align: center;
  margin: 0 auto 30px;
  .title {
    font-size: 25px;
    line-height: 31px;
  }
  .text {
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
  }
  @include breakpoint(md) {
    max-width: 976px;
    margin-bottom: 50px;
    .text {
      font-size: 16px;
      line-height: 30px;
    }
    .title {
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 10px;
    }
  }
  @include breakpoint(lg) {
    max-width: 976px;
    margin-bottom: 70px;
    .text {
      font-size: 20px;
      line-height: 35px;
    }
    .title {
      font-size: 40px;
      line-height: 50px;
    }
  }
}
.__details-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  .__details-form {
    width: 100%;
  }
  .__ticket-wrapper {
    flex-grow: 1;
    &-title {
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 15px;
      @include breakpoint(md) {
        font-size: 25px;
        line-height: 31px;
        margin-bottom: 20px;
      }
    }
  }
  @include breakpoint(lg) {
    gap: 40px;
    .__details-form {
      max-width: 480px;
    }
    .__ticket-wrapper {
      flex-grow: 1;
      width: 300px;
    }
  }
  @include breakpoint(xl) {
    gap: 60px;
    .__details-form {
      max-width: 600px;
    }
    .__subscribe-form {
      max-width: 2000px;
    }
    .__ticket-wrapper {
      flex-grow: 1;
      width: 300px;
    }
  }
}
.__subscribe-form {
  padding: 30px 20px;
  background: var(--section);
  border-radius: 15px;
  &-title {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
  }
  &-subtitle {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
    margin-top: 45px;
  }
  @include breakpoint(md) {
    padding: 50px 50px 30px;
    &-title {
      font-size: 25px;
      line-height: 31px;
      margin-bottom: 30px;
    }
    &-subtitle {
      font-size: 25px;
      line-height: 31px;
      margin-bottom: 30px;
    }
  }
  @include breakpoint(lg) {
    padding: 30px;
  }
  @include breakpoint(xl) {
    padding: 50px 50px 30px;
  }
  .text-sm {
    font-size: 10px;
    line-height: 13px;
  }
}
.__details-form {
  padding: 30px 20px;
  background: var(--section);
  border-radius: 15px;
  &-title {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
  }
  &-subtitle {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
    margin-top: 45px;
  }
  @include breakpoint(md) {
    padding: 50px 50px 30px;
    &-title {
      font-size: 25px;
      line-height: 31px;
      margin-bottom: 30px;
    }
    &-subtitle {
      font-size: 25px;
      line-height: 31px;
      margin-bottom: 30px;
    }
  }
  @include breakpoint(lg) {
    padding: 30px;
  }
  @include breakpoint(xl) {
    padding: 50px 50px 30px;
  }
  .text-sm {
    font-size: 10px;
    line-height: 13px;
  }
}
.__form-control {
  height: 50px;
  background: var(--bg-color);
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 10px;
  &:dropdown {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
  }
  &:focus {
    box-shadow: none;
    outline: none;
    background: var(--bg-color);
    color: var(--white);
  }
  &::placeholder {
    color: var(--body-clr);
  }
  font-size: 18px;
  @include breakpoint(md) {
    height: 60px;
  }
  color: var(--white);
  &[type="number"] {
    &::-webkit-inner-spin-button {
      display: none;
    }
    &::-webkit-outer-spin-button {
      display: none;
    }
  }
}
.__form-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: "BR Frima Semibold";
  color: var(--white);
}
.submitBtn {
  background: var(--gradient);
  border-radius: 15px;
  color: var(--white);
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  height: 50px;
  margin-top: 9px;
  border: none;
  outline: none;
  box-shadow: none;
  @include breakpoint(sm) {
    height: 60px;
    margin-top: 14px;
  }
}
.__ticket-checkout {
  background: #0f192f;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  @include breakpoint(xl) {
    padding: 20px 20px 20px 30px;
  }
  @include breakpoint(md) {
    @include breakpoint(max-lg) {
      padding: 20px 20px 20px 30px;
    }
  }
  .subtitle {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
    color: var(--white);
    @include breakpoint(xl) {
      font-size: 25px;
      line-height: 31px;
    }
    @include breakpoint(md) {
      @include breakpoint(max-lg) {
        font-size: 25px;
        line-height: 31px;
      }
    }
  }
  .total-price {
    font-size: 18px;
    line-height: 23px;
    color: var(--green);
    margin: 0;
    @include breakpoint(xl) {
      font-size: 25px;
      line-height: 31px;
    }
    @include breakpoint(md) {
      @include breakpoint(max-lg) {
        font-size: 25px;
        line-height: 31px;
      }
    }
  }
}
.__ticket_promo {
  padding: 15px;
  background: #0f192f;
  border-radius: 15px;
  align-items: center;
  margin-bottom: 20px;
}
.__ticket_question {
  padding: 15px;
  background: var(--bg-color);
  border-radius: 15px;
  align-items: center;
  margin-bottom: 20px;
  &-question {
    color: var(--body-clr);
  }
}
.__ticket-item {
  display: flex;
  padding: 15px;
  background: #0f192f;
  border-radius: 15px;
  align-items: center;
  margin-bottom: 20px;
  &-icon {
    width: 80px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg-color);
    border-radius: 15px;
    svg {
      width: 30px;
    }
  }
  &-cont {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    padding-left: 15px;
    .info {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: var(--body-clr);
      small {
        font-size: 9px;
        margin-right: 3px;
      }
    }
    .subtitle {
      font-family: "BR Frima Semibold";
      font-size: 16px;
      line-height: 20px;
      margin: 5px 0;
    }
    .price {
      color: var(--green);
      font-size: 16px;
      line-height: 20px;
      transform: translate(-15px, 25px);
    }
    .promo-item-price {
      color: var(--pink);
      font-size: 16px;
      line-height: 20px;
      transform: translate(-15px, 25px);
    }
    .right-detail {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .price {
        color: var(--green);
        font-size: 16px;
        line-height: 20px;
        transform: translate(-15px, 25px);
      }
      .quantity {
        color: var(--secondary);
        font-size: 16px;
        line-height: 20px;
        margin-right: 6px;
        width: 40%;
        background: var(--bg-color);
      }
    }
  }
  @include breakpoint(xl) {
    padding: 20px;
    &-icon {
      width: 96px;
      svg {
        width: 40px;
      }
    }
    &-cont {
      .subtitle {
        font-size: 20px;
        line-height: 25px;
      }
      .right {
        .price {
          font-family: "BR Frima Bold";
          font-size: 20px;
          transform: translate(-15px, 0);
        }
        .quantity {
          font-family: "BR Frima Bold";
          font-size: 20px;
          transform: translate(-15px, 0);
        }
      }
    }
  }
  @include breakpoint(md) {
    @include breakpoint(max-lg) {
      padding: 20px;
      &-icon {
        width: 96px;
        svg {
          width: 40px;
        }
      }
      &-cont {
        .right {
          .price {
            transform: translate(-15px, 0);
          }
          .quantity {
            transform: translate(-15px, 0);
          }
        }
      }
    }
  }
}
.qty-btns {
  background: var(--bg-color);
  border-radius: 10px;
  padding: 6px 8px;
  display: flex;
  flex-direction: column;
  .qty-btn {
    cursor: pointer;
    &:first-child {
      border-bottom: 1px solid rgba(217, 217, 217, 0.1);
    }
    svg {
      width: 20px;
      height: 33px;
    }
  }
}
.applyPromo:hover {
  border-color: var(--white);
}
.applyPromo {
  background: transparent;
  border-radius: 15px;
  color: var(--pink);
  font-size: 14px;
  margin: 0 0 0 12px;
  padding: 4px 20px;
  line-height: 18px;
  height: 50px;
  border: 1px solid var(--pink);
  outline: none;
  box-shadow: none;
  text-transform: uppercase;
  text-decoration: none;
  font-family: "BR Frima Bold";
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  &:hover {
    color: var(--white);
  }
  @include breakpoint(sm) {
    height: 60px;
    font-size: 16px;
    line-height: 20px;
    min-width: 100px;
  }
}
.checkoutBtn {
  background: var(--gradient);
  border-radius: 15px;
  color: var(--white);
  font-size: 14px;
  margin: 12px;
  padding: 4px;
  line-height: 18px;
  height: 60px;
  border: none;
  outline: none;
  box-shadow: none;
  text-transform: uppercase;
  text-decoration: none;
  font-family: "BR Frima Bold";
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  &:hover {
    color: var(--white);
  }
  @include breakpoint(sm) {
    height: 60px;
    font-size: 16px;
    line-height: 20px;
    min-width: 280px;
  }
}
.select-item {
  position: relative;
  background: var(--bg-color);
  border-radius: 10px;
  .__form-control {
    background: transparent !important;
    position: relative;
    z-index: 2;
    width: 97px;
    option {
      color: var(--body);
    }
  }
  .icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.w-0 {
  width: 0;
}
.flex-grow {
  flex-grow: 1;
}
.blockLoader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 222222;
  background: var(--blue2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.blockLoader2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 222222;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.blockLoader2 .loderInner {
  width: 250px;
  height: 200px;
  border-radius: 20px;
  background: var(--blue2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.qrcode {
  width: 50%;
  margin-bottom: 5%;
}
.qrcode-ticket {
  width: 90%;
  margin-bottom: 5%;
}
.cashless-modal {
  .title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 21px;
    text-align: center;
    padding-top: 16px;
  }
  .modal-content {
    background: var(--blue2) !important;
  }
}
.submitBtn2 {
  font-size: 16px;
  font-weight: 700;
  font-family: "BR Frima Bold";
  height: 60px;
  text-transform: uppercase;
}
.backBtn {
  height: 60px;
  color: var(--violet);
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
}
.total--price {
  display: flex;
  justify-content: space-between;
  color: var(--white);
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
  font-family: "BR Frima Bold";
  margin-bottom: 20px;
  margin-top: 20px;
}
.cashless--title {
  color: var(--white);
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
  font-family: "BR Frima Bold";
  margin-bottom: 20px;
  margin-top: 20px;
}
.cashless-item {
  display: flex;
  align-items: center;
  padding: 15px;
  background: #081127;
  border-radius: 20px;
  .img {
    background: rgba(#fff, 0.05);
    border-radius: 15px;
    padding: 12px;
    width: 80px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  .content {
    width: 0;
    flex-grow: 1;
    padding-right: 42px;
    padding-left: 12px;
    .subtext {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 5px;
      color: var(--body-clr);
      text-transform: uppercase;
      font-family: "BR Frima";
    }
    .subtitle {
      font-size: 16px;
      font-weight: 600;
      color: var(--white);
      margin-bottom: 5px;
      font-family: "BR Frima Semibold";
    }
    .price {
      display: flex;
      justify-content: space-between;
      color: var(--white);
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      font-family: "BR Frima Bold";
    }
  }
  position: relative;
  .button-group {
    position: absolute;
    height: calc(100% - 4px);
    right: 2px;
    top: 2px;
    button {
      background: transparent;
      border: none;
      outline: none;
      padding: 0;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16.5px 8px;
    background: #293243;
    border-radius: 20px;
    width: 40px;
    .divider {
      border-bottom: 1px solid rgba(#d9d9d9, 0.1);
    }
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
.page-title {
  font-family: var(--gilroy-bold);
  font-size: 30px;
  text-align: center;
  color: var(--white);
  position: relative;
  padding-block: 27px;
  .back-icon {
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.main-page {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 174px;
    background: var(--blue2);
  }
  .container {
    position: relative;
  }
}
.price--area {
  background: linear-gradient(105.61deg, #577cff 1.03%, #0038ff 99.09%);
  border-radius: 15px;
  padding: 50px 20px 22px;
  font-family: var(--gilroy-bold);
  text-align: center;
  .price {
    font-size: 40px;
    font-weight: 400;
    margin: 0;
  }
  .subtext {
    font-size: 25px;
    font-weight: 400;
    color: var(--white);
    font-family: "Gilroy-Medium";
  }
  .text {
    font-family: "BR Frima Bold";
    font-size: 20px;
    color: var(--white);
  }
}
.mb-30 {
  margin-bottom: 30px;
}
.text-22 {
  font-size: 22px;
  line-height: 27px;
}
.gilroy-bold {
  font-family: var(--gilroy-bold);
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-44 {
  margin-bottom: 44px;
}
.mt-9 {
  margin-top: 9px;
}
.mb-40 {
  margin-bottom: 40px;
}
.font-medium {
  font-weight: 500;
}
.text-8BA4BF {
  color: var(--body-clr);
}
.max-w-303px {
  max-width: 303px;
}
.no-gutter {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  outline: none;
}
.page-header {
  background: var(--blue2);
  padding: 32px 0 20px;
  font-size: 14px;
  font-weight: 500;
  color: var(--body-clr);
  .title {
    font-size: 28px;
    font-weight: 700;
    color: var(--white);
    font-family: "BR Frima Bold";
    margin-bottom: 10px;
  }
  .container {
    padding-inline: 24px;
  }
}
.mb-12px {
  margin-bottom: 12px;
}
.summary-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--white);
  margin-bottom: 15px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.summary-title-2 {
  font-weight: 600;
  font-size: 22px;
  line-height: 27.65px;
  color: var(--white);
  margin-bottom: 15px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.summary {
  background: #162136;
  border-radius: 20px;
  padding: 4px 20px;
  ul {
    padding: 0;
    margin: 0;
    li {
      display: flex;
      justify-content: space-between;
      font-size: 22px;
      font-weight: 500;
      line-height: 27.65px;
      color: var(--white);
      padding: 21px 0;
      list-style: none;
      .count {
        font-family: "BR Frima Semibold";
        color: var(--violet);
        font-size: 26px;
        font-weight: 800;
      }
      &:not(:last-child) {
        border-bottom: 1px solid rgba(#d9d9d9, 0.1);
      }
    }
  }
}
.px-20px {
  padding-inline: 20px !important;
}
@media screen and (max-width: 575px) {
  .cashless-modal {
    .modal-dialog {
      margin: 0;
      padding: 20px 22px;
    }
  }
}
.filter-menu-container {
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 0;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.filter-menu {
  display: flex;
  gap: 5px;
  li {
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
    padding: 8px 25px;
    gap: 9px;
    font-size: 15px;
    font-weight: 600;
    line-height: 19.5px;
    color: var(--grey);
    background: var(--blue);
    border-radius: 30px;
    min-height: 60px;
    justify-content: center;
    &:first-child {
      padding: 5px 25px;
      min-width: 9px;
      border-radius: 30px;
    }
    &.active {
      background: linear-gradient(91.24deg, #4200ff 0.26%, #ff02c7 99.58%);
      color: var(--white);
    }
  }
}
.text-30 {
  font-size: 30px !important;
}
.caise-menu-box {
  background: var(--blue2);
  border-radius: 15px;
  padding: 15px 12px 5px;
  text-align: center;
  img {
    aspect-ratio: 1;
    width: 90px;
    margin-bottom: 12px;
  }
  .name {
    font-size: 14px;
    font-weight: 500;
    line-height: 17.6px;
    color: var(--white);
  }
  .price {
    font-size: 16px;
    font-weight: 700;
    line-height: 17.6px;
    color: var(--green);
  }
  position: relative;
  .click {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
.pb-30 {
  padding-bottom: 30px;
}
.btn-1,
.btn-2 {
  min-height: 60px;
  border-radius: 25px;
  color: var(--white);
  font-size: 15px;
  font-weight: 800;
  line-height: 19.5px;
  border: none;
  outline: none;
  box-shadow: none;
}
.btn-2 {
  background: var(--green);
}
.btn-1 {
  background: #0038ff;
}
.close-1,
.close-2 {
  position: absolute;
  left: 15px;
  top: 12px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  border-radius: 7px;
  font-size: 15px;
  font-weight: 600;
  z-index: 3;
}

.close-1 {
  background: #f30202;
}
.close-2 {
  background: var(--violet);
  left: unset;
  right: 15px;
}
.zi-50 {
  z-index: 50;
}
.px-20px {
  padding-inline: 20px;
}
.cart-item {
  background: var(--blue2);
  padding: 20px;
  .content {
    padding-right: 70px;
  }
  .button-group {
    background: var(--blue);
    border-radius: 10px;
    top: 15px;
    right: 15px;
    height: calc(100% - 30px);
    padding-block: 0;
    button {
      flex-grow: 1;
    }
  }
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}
.custom-dropdown-menu {
  background-color: #202d4a;
  z-index: 9999;
  padding-top: 10px;
  position: relative;
  max-height: 150px; /* Adjust the height as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Hides horizontal scrollbar */
}
.custom-dropdown {
  padding-top: 9px;
}

.form-select {
  width: 100%; /* Full width of the container */
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #202d4a;
  background-color: #202d4a;
  color: var(--grey);
}
.input-promo-container {
  flex: 1;
}
.apply-promo-container {
  width: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.ticket-promo-container {
  display: flex;
  flex-direction: row;
}
// dropdown
.Dropdown-root {
  position: relative;
}
.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: var(--bg-color);
  border: 1px solid var(--grey);
  border-radius: 2px;
  box-sizing: border-box;
  color: var(--white);
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
}
.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}
.Dropdown-arrow {
  border-color: var(--light-grey2) transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 5px;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}
.is-open .Dropdown-arrow {
  border-color: transparent transparent var(--light-grey2);
  border-width: 0 5px 5px;
}
.Dropdown-menu {
  background-color: var(--bg-color);
  border: 1px solid var(--grey);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}
.Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
  text-transform: capitalize;
}
.Dropdown-option {
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}
.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.Dropdown-option:hover {
  background-color: var(--dropdown-bg-color);
  color: var(--dark-grey);
}
.Dropdown-option.is-selected {
  background-color: var(--dropdown-bg-color);
  color: var(--dark-grey);
}
.Dropdown-noresults {
  box-sizing: border-box;
  color: var(--grey);
  cursor: default;
  display: block;
  padding: 8px 10px;
}
// bottom section
.__bottom_wrapper {
  padding: 30px 20px;
  background: var(--section);
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 30px;
  .__localization-form {
    width: 100%;
    &-title {
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 15px;
    }
  }
  .__ticket-calendar {
    flex-grow: 1;
    .__ticket-calendar-items {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-bottom: 25px;
      @include breakpoint(max-md) {
        flex-direction: column;
      }
    }
    .__ticket-contact-details {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
    .__ticket-contact-social {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
    &-title {
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 15px;
    }
  }
  @include breakpoint(lg) {
    gap: 40px;
    .__localization-form {
      max-width: 480px;
    }
    .__ticket-calendar {
      flex-grow: 1;
      width: 300px;
    }
  }
  @include breakpoint(xl) {
    gap: 60px;
    .__localization-form {
      max-width: 575px;
    }
    .__ticket-calendar {
      flex-grow: 1;
      width: 300px;
    }
  }
}
.location-container {
  display: flex;
  flex-direction: row;
  &.__label-container {
    flex: 1;
  }
}
.__form-label-location {
  display: flex;
  flex-direction: column;
}
.__ticket-location {
  display: flex;
  padding: 15px;
  background: var(--bg-color);
  border-radius: 15px;
  align-items: center;
  margin-bottom: 20px;
  &-innercont {
    display: flex;
    flex-direction: column;
  }
  &-cont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding-left: 0;
    .info {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: var(--body-clr);
      small {
        font-size: 9px;
        margin-right: 3px;
      }
    }
    .subtitle {
      font-family: "BR Frima Semibold";
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin: 10px 0 5px 0;
      color: var(--white);
    }
    .right-detail {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .applyPromo {
        height: 60px;
      }
      @include breakpoint(sm) {
        .apply-promo-container {
          width: 100%;
          margin-top: 15px;
        }
        .applyPromo {
          margin: 0px;
          width: 100%;
        }
      }
      @include breakpoint(max-sm) {
        .apply-promo-container {
          width: 100%;
          margin-top: 15px;
        }
        .applyPromo {
          margin: 0px;
          width: 100%;
          min-width: 170px;
        }
      }
      @include breakpoint(md) {
        .apply-promo-container {
          width: 100%;
          margin-top: 15px;
        }
        .applyPromo {
          margin: 0px;
          width: 100%;
          min-width: 170px;
        }
      }
      @include breakpoint(max-md) {
        .apply-promo-container {
          width: 100%;
          margin-top: 15px;
        }
        .applyPromo {
          margin: 0px;
          width: 100%;
          min-width: 170px;
        }
      }
    }
    @include breakpoint(md) {
      flex-direction: column;
    }
    @include breakpoint(lg) {
      flex-direction: row;
    }
    @include breakpoint(xl) {
      flex-direction: row;
    }
    @include breakpoint(xxl) {
      flex-direction: row;
    }
  }
  @include breakpoint(xl) {
    padding: 20px;
    &-cont {
      .subtitle {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
  @include breakpoint(md) {
    @include breakpoint(max-lg) {
      padding: 20px;
      &-icon {
        width: 96px;
      }
    }
  }
}
.__ticket-date {
  display: flex;
  padding: 15px;
  background: var(--bg-color);
  border-radius: 15px;
  align-items: center;
  margin-bottom: 20px;
  flex: 1;
  @include breakpoint(max-md) {
    padding: 0px;
  }
  &-icon {
    width: 60px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg-color);
    border-radius: 15px;
    svg {
      width: 30px;
    }
  }
  &-cont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding-left: 0;
    .subtitle {
      font-family: "BR Frima Semibold";
      font-size: 25px;
      font-weight: 500;
      line-height: 20px;
      margin: 10px 0 5px 0;
      color: var(--white);
      @include breakpoint(max-md) {
        font-size: 16px;
      }
    }
    .info {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: var(--body-clr);
      @include breakpoint(max-md) {
        font-size: 12px;
      }
    }
  }
}
.ticket-map {
  border-radius: 0px;
  height: 295px;
  width: auto;
  border: 1px solid var(--light-grey1);
  background-color: var(--grey);
  @include breakpoint(max-md) {
    height: 180px;
  }
}
.__ticket-contact-area {
  display: flex;
  padding: 15px;
  background: var(--bg-color);
  border-radius: 15px;
  align-items: center;
  margin-bottom: 20px;
  flex: 1 1;
  @include breakpoint(max-md) {
    flex-direction: column;
  }
  &-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 100%;
  }
  &-image {
    margin-right: 20px;
    width: 80px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg-color);
    border-radius: 50%; // Modifié pour rendre l'image ronde
    @include breakpoint(max-md) {
      width: 48px;
    }
    img {
      width: 100%; // Assurez-vous que l'image couvre tout le conteneur
      height: auto; // Garde le ratio d'aspect de l'image
      border-radius: 50%; // Applique également le bord arrondi à l'image
      @include breakpoint(max-md) {
        width: 100%;
      }
    }
  }

  &-title {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-left: 0;
    justify-content: center;
    align-items: flex-start;
    .title {
      font-family: "BR Frima Semibold";
      font-size: 25px;
      font-weight: 500;
      line-height: 20px;
      margin: 10px 0 5px 0;
      color: var(--white);
      @include breakpoint(max-md) {
        font-size: 16px;
      }
    }
    .email {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-transform: capitalize;
      color: var(--body-clr);
      @include breakpoint(max-md) {
        font-size: 12px;
      }
    }
  }
  &-button {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg-color);
    border-radius: 15px;
    @include breakpoint(max-md) {
      width: 100%;
      margin-top: 20px;
    }
    .contactBtn:hover {
      border-color: var(--white);
      color: var(--white);
    }
    .contactBtn {
      background: transparent;
      border-radius: 15px;
      color: var(--pink);
      font-size: 14px;
      margin: 0px;
      padding: 4px 20px;
      line-height: 18px;
      height: 60px;
      border: 1px solid var(--pink);
      outline: none;
      box-shadow: none;
      text-transform: uppercase;
      text-decoration: none;
      font-family: "BR Frima Bold";
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100px;
      @include breakpoint(max-md) {
        width: 100%;
      }
    }
  }
}
.__ticket-social-area {
  display: flex;
  padding: 15px;
  background: var(--bg-color);
  border-radius: 15px;
  align-items: center;
  margin-bottom: 20px;
  flex: 1 1;
  @include breakpoint(max-md) {
    flex-direction: column;
  }
  &-title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding-left: 0;
    .title {
      font-family: "BR Frima Semibold";
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
      margin: 10px 0 5px 0;
      color: var(--white);
      @include breakpoint(max-md) {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
  }
  &-buttons {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg-color);
    border-radius: 15px;
    gap: 20px;
    img {
      width: 32px;
      cursor: pointer;
    }
  }
}
/* checkbox */
.checkbox .container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 7px 0 0 0;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--white);
  @include breakpoint(max-md) {
    font-size: 14px;
  }
}
.checkbox .container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: var(--light-grey);
}
.checkbox .container-checkbox:hover input ~ .checkmark {
  background-color: var(--grey);
}
.checkbox .container-checkbox input:checked ~ .checkmark {
  background-color: var(--green);
}
.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox .container-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.checkbox .container-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 8px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.question-container {
  margin-top: 10px;
}
.custom-dropdown .Dropdown-control {
  border: none; /* Retirer la couleur de la bordure */
  //border-radius: 8px; /* Ajouter un border-radius */
  //box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1); /* Optionnel : légère ombre pour le focus */
  ///padding: 8px 12px; /* Ajuster le padding si nécessaire */
  background-color: transparent;
}

.custom-dropdown .Dropdown-menu {
  border-radius: 8px; /* Ajouter le border-radius au menu déroulant également */
  overflow-y: auto; // Enables vertical scrolling
}

.custom-dropdown .Dropdown-option {
  padding: 10px 12px; /* Optionnel : ajuster le padding des options */
}

.languageDropdownWrapper {
  grid-gap: 12px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.languageAndDownloadContentWrapper {
  grid-gap: 12px;
}

.languageDropdown .dropdown-toggle {
  display: flex; // Enables Flexbox
  align-items: center; // Vertically centers the items
  justify-content: center; // Horizontally centers the items
  gap: 10px; // Adds space between the flag and the label
  background-color: var(--bg-color); // Set the background color to pink
  border-color: var(
    --bg-color
  ); // Optional: Set the border color to pink if there is a border
}

.languageDropdown :global .btn {
  background: transparent;
  padding: 1.2rem;
  border-radius: 12px;
  border: solid 2px #223254;
  font-size: 12;
  font-weight: normal;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  transition: 0.35s;
}

.languageDropdown .dropdown-toggle::after {
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
}

.languageDropdown .dropdown-item {
  font-size: 12;
  color: white;
  transition: 0.35s all;
}

.languageDropdown .dropdown-item:not(:last-child) {
  margin-bottom: 10px;
}

.languageDropdown .dropdown-menu {
  background: var(--bg-color);
  border-radius: 12px;
  width: 100%;
}

.languageDropdown .dropdown-item:focus,
.languageDropdown .dropdown-item:hover {
  background: var(--light-grey2);
}

.languageDropdown .dropdown-item.active,
.languageDropdown .dropdown-item:active {
  background-color: unset;
}

.flagIcon {
  --flag-sizing: 1rem;
  width: var(--flag-sizing);
  height: var(--flag-sizing);
}
