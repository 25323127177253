.organization-card {
	color: #8ba4bf;
	.card {
		.card-header {
			background-color: #23314f !important;
			height: 175px;
		}
		.__form-control {
			background-color: #202d4a;
		}
		.form-label {
			color: #8ba4bf;
		}
		.form-select {
			background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
		}
	}
}
.orgs-logo {
	width: 120px;
	border-radius: 50%;
	aspect-ratio: 1;
	object-fit: cover;
	border: 7px solid #142240;
	@media (min-width: 768px) {
		width: 150px;
	}
}

.orgs-name {
	font-size: 26px;
	font-weight: 600;
	width: 0;
	flex-grow: 1;
	padding-bottom: 12px;
	@media (min-width: 768px) {
		width: 150px;
		padding-bottom: 18px;
	}
}
.orgs-author {
	margin-top: -85px;
	margin-bottom: 30px;
	@media (min-width: 768px) {
		margin-bottom: 40px;
	}
}
@media (min-width: 768px) {
	.sm-min-h-100px {
		min-height: 72px;
	}
}
@media (min-width: 576px) {
	.sm-border-right {
		border-right: 1px solid rgba(#fff, 0.1);
	}
	.pr-sm-20 {
		padding-right: 20px;
	}
	.pl-sm-20 {
		padding-left: 20px;
	}
}
.w-180px {
	width: 180px;
}
